import React from 'react';
import classNames from 'classnames';
import { SEO } from '../../components/SEO';
import { Header } from '../../components/Header';
import { Layout } from '../../layouts/Layout';
import { Footer } from '../../components/Footer';
import { Video } from '../../components/Video';
import LogoTypoMonoSVG from '../../resources/logo_typo_mono.svg';

const contents = [
  {
    title: '상지 및 손기능 강화',
    content: `
      작업치료는 질병이나 사고로 인하여 신체적, 정신적인 손상이 있는 환자에게 능동적이고 목적 있는 활동을 제공하여 의미 있고, 유용한 작업을 수행하도록 하는데 목적이 있습니다. 
    `,
    video: '/video/J_Work_01.mp4',
  },
  {
    title: '상지 가동범위 증진',
    content: `
      작업치료는 기능을 향상시키고 악화를 예방하기 위해 세부적이고 섬세한 개별 평가를 진행하며 개인에 맞는 치료를 적용함으로써 잠재적이거나 변화할 수 있는 다양한 기능을 높입니다.
    `,
    video: '/video/J_Work_02.mp4',
  },
  {
    title: '손의 미세움직임 증진',
    content: `
      근력강화, 능동적 상지움직임 증진, 손기능 증진 등을 목표로 하여 다양한 활동 및 과제를 제공하고 수행을 돕는 치료 입니다. 환자의 삶의 질을 높이고 만족스러운 일상활동이 가능하도록 다양한 기술을 습득하거나 보완, 훈련하는 치료를 적용하기도 합니다.
    `,
    video: '/video/J_Work_03.mp4',
  },
  {
    title: '일상생활동작 중 옷입기 훈련',
    content: `
      환자분들의 퇴원 후 일상생활 복귀를 위해서 생활 속에서 기본적으로 필요한 모든 동작 수행능력을 평가하고 개선합니다. (옷 입기, 밥 먹기, 씻기, 화장실 사용 등)
    `,
    video: '/video/J_Work_04.mp4',
  },
  {
    title: '일상생활동작 중 세면대 이용훈련',
    content: `
      주거환경과 비슷하게 세팅된 치료실 안에서 치료사의 지도 하에, 다양한 활동 시뮬레이션 및 훈련을 할 수 있습니다.
    `,
    video: '/video/J_Work_05.mp4',
  },
  {
    title: '안면 근육 및 삼킴근육 전기자극치료',
    content: `
      삼킴에 관여하는 구강, 인두강, 후두강이나 식도관등의 구조 자체에 결함이 있거나 기능장애가 발생하여 적절한 음식 섭취가 이루어지지 않아서 영양공급에 방해가 될 수 있으며, 음식물 등의 기도 흡인으로 인해 흡인성 폐렴이 발생할 수 있습니다. 
    `,
    video: '/video/J_Work_06.mp4',
  },
  {
    title: '삼킴 훈련 & 구강 감각 자극치료',
    content: `
      연하장애는 특히 고령환자 및 뇌졸중 환자에게서 발생하며 자세, 목의 위치, 턱과 혀의 움직임, 구강 감각과 인후두 기능 저하 등 여러 가지 복합적인 요인으로 발생하기 때문에 작업치료사는 병력, 정신 상태, 신체 기능 및 평가된 구강기능검사를 통해 기능저하의 객관적인 원인을 분석하고 환자에 맞는 적절한 식이요법과 삼킴운동을 실시합니다.
    `,
    video: '/video/J_Work_07.mp4',
  },
  {
    title: '모양 및 색깔 구별을 통한 인지훈련',
    content: `
      인지와 지각 장애는 일상생활을 영위하는데 심각한 장애를 발생시킬 수 있습니다. 따라서 치료사는 전문적인 진단과 평가를 기반으로 환자의 정확한 문제점을 파악하고 다양한 인지치료 전략을 세워 독립적인 일상기능으로 복귀하도록 도움을 줍니다. 집중력, 기억력, 지각력, 시공간구별, 문제해결능력, 언어력 등의 결함에 따라서 필요한 정보를 반복 학습하거나, 여러 가지 보조 도구를 이용하여 다양한 인지전략을 가르치고, 다양한 활동과 경험을 통해 뇌를 훈련 시킬 수 있습니다.
    `,
    video: '/video/J_Work_08.mp4',
  },
];

const Section = ({ className = '', title, content, video }) => {
  return (
    <div
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full m-auto text-white max-w-screen-sm mx-auto',
        className
      )}
    >
      <div className="relative w-full aspect-square">
        <Video src={video} className="w-full h-full" />
        <div
          className="absolute bottom-0 left-0 right-0 h-[122px] flex justify-between px-6 items-end"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(123, 116, 109, 0.85) 69%, #635b53)`,
          }}
        >
          <h1
            className="text-2xl font-extrabold text-white"
            style={{
              wordBreak: 'keep-all',
              textShadow: `0 2px 8px rgba(0, 0, 0, 0.65)`,
            }}
          >
            {title}
          </h1>
          <LogoTypoMonoSVG className="w-auto h-[26px]" />
        </div>
      </div>
      <div className="px-6 py-6 text-[16px] pb-12 min-h-[180px]" style={{ lineHeight: 1.75 }}>
        {content}
      </div>
    </div>
  );
};

export default function RehabDetailPage({ title = '작업 치료', ...props }) {
  return (
    <Layout className="relative bg-slate">
      <SEO title={title} titleTemplate="" />
      <Header showBreadcrumb noHeight backHref="/rehab" className="sticky top-0 left-0 right-0 z-10" />
      <div className="flex w-full h-[60px] items-center justify-center font-bold text-xl bg-slate text-white">
        {title}
      </div>

      {contents.map((props, idx) => (
        <Section {...props} key={idx} />
      ))}

      <Footer />
    </Layout>
  );
}
